import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching from '../components/images/imageCoaching.js'
import ImageDevelopement from '../components/images/imageDevelopement.js'
import ImageClimat from '../components/images/imageClimat.js'
import ImageChange from '../components/images/imageChange.js'
import ImageConflit from '../components/images/imageConflit.js'
import ImageLeft1 from '../components/images/imageLeft1.js'
import linkedIn from '../images/linkedin.svg'

const GestionChangement = () => (
  <Layout>
    <SEO title="À propos | Gestion Mieux-Être" />
    <PageHeader
        header=""
        text="Spécialiste du développement organisationnel depuis plus de 25 ans, Natalie se passionne pour le développement humain et l’évolution des organisations. Elle fonde sa pratique professionnelle sur sa formation en psychologie organisationnelle et son expérience à titre de gestionnaire au sein de grandes entreprises québécoises. Elle enseigne également à HEC Montréal. "
        quote="En s’appuyant sur la mise en place de saines pratiques, nous pouvons contribuer activement à l’atteinte de vos succès.  <span>- Gestion mieux-être</span>"
        showOwner
        background="mieuxetre" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">
        <h2>Natalie Bertrand</h2>
          <a target="_blank" href="http://linkedin.com/in/bertrandnatalie">
              <img className="social" src={linkedIn} />
          </a>
        <p class="service-presentation2">Son approche collaborative prend assise sur son expérience de gestionnaire et ses connaissances approfondies du développement organisationnel et de la dynamique des transformations.  Elle aborde avec doigté et compétence les aspects humains et structurels de nos organisations. </p>
        <p class="service-presentation2">Elle accompagne les leaders qui souhaitent être inspirés et inspirants. À l'affût des tendances, ses interventions s'appuient sur les meilleures pratiques. <br /><br />Natalie est en mesure de participer activement à l'accélération de votre croissance.  Vous vous sentez interpellé?   </p>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>
        <br /><br />
        <p>Suivez GESTION MIEUX-ÊTRE :</p>
        <a target="_blank" href="https://www.linkedin.com/company/gestion-mieux-etre">
            <img className="social" src={linkedIn} />
        </a>

    </div>
    <div className="white-back">
        <div className="main-wrapper pad">
            <ScrollAnimation
                delay="0"
                animateIn="fadeIn">
            <h2 style={{'padding-left':'10%'}}>Un collectif de talents</h2>
            <p class="service-presentation2" style={{'font-style':'italic'}}>Le cabinet-conseil GESTION MIEUX-ÊTRE regroupe, selon la nature des mandats, des partenaires de grands talents.   Notre collectif est composé d'experts seniors qui présentent des parcours diversifiés avec des expertises complémentaires comme le leadership, la transformation, le recrutement, la gestion des talents, la résolution de conflits, le coaching de gestion et la mobilisation d’équipe.  </p>
            <p class="service-presentation2" style={{'font-style':'italic'}}>Spécialistes chevronnés, nos partenaires se démarquent par leur compréhension poussée dans plusieurs domaines d’expertise. Ils vous inspireront en vous faisant profiter de leur vaste expérience.  Ainsi, avec ces expertises démultipliées, nous vous accompagnons afin de créer une réelle différence dans votre écosystème.</p>
            </ScrollAnimation>
        </div>
    </div>
  </Layout>
)

export default GestionChangement
